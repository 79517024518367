import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <article className="blog-post">
        <Seo title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />
        <div style={{display:'flex', justifyContent:'center'}}><h3 style={{marginBottom: '5px'}}>{post.frontmatter.title}</h3></div> 
        <div dangerouslySetInnerHTML={{  __html: post.html }} ></div>
      </article>

      <nav className="blog-post-nav">       
        <ul>
          <li> {previous && ( <Link to={previous.fields.slug} rel="prev"> ← {previous.frontmatter.title} </Link>)} </li>
          <li> {next && ( <Link to={next.fields.slug} rel="next"> {next.frontmatter.title} → </Link>)} </li>
        </ul>
      </nav>
    </Layout>
  ) 
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
